<template>
  <v-container>
    <v-row>
      <v-col>
        <span class="text-h4">Turnos Semanales</span>
      </v-col>
      <v-col>
        <v-btn to="/reportes" icon small absolute right fab>
          <v-icon>mdi-chevron-left</v-icon>
        </v-btn>
      </v-col>
    </v-row>

    <v-divider></v-divider>
    <v-row
      ><v-col>
        <br />
        <TurnosSemanalesFilter
          ref="reportFilter"
          @turnossemanales-changed="processTurnos"
          :pageNumber="pageNumber"
        >
        </TurnosSemanalesFilter> </v-col
    ></v-row>

    <v-row>
      <v-col md="12">
        <vue-html2pdf
          :show-layout="false"
          :float-layout="true"
          :enable-download="true"
          :preview-modal="false"
          :paginate-elements-by-height="1400"
          :filename="
            empleado ? 'Turnos Semanales - ' + empleado : 'Turnos Semanales'
          "
          :pdf-quality="2"
          :manual-pagination="true"
          pdf-format="a4"
          pdf-orientation="portrait"
          pdf-content-width="800px"
          @hasStartedGeneration="hasStartedGeneration()"
          @hasGenerated="hasGenerated($event)"
          ref="html2Pdf"
        >
          <section slot="pdf-content" class="pdf-content">
            <!-- PDF Content Here -->
            <h3 class="title">Turnos Semanales</h3>
            <br />
            <br />
            <br />
            <h4 class="subtitle">
              Rango: {{ this.rango }} {{ this.desde }} , Empleado:
              {{ empleado ? empleado : "Todos" }}, Sector:
              {{ this.sector }}
            </h4>
            <section class="pdf-item" v-if="!empleadosTurnos">
              <h4>No se encontraron resultados</h4>
            </section>

            <section
              class="pdf-item"
              v-for="empleadoTurnos in empleadosTurnos"
              :key="empleadoTurnos.empleado.documento_nro"
            >
              <h4>
                Empleado:
                {{
                  empleadoTurnos.empleado.apellidos +
                  ", " +
                  empleadoTurnos.empleado.nombres
                }}
              </h4>
              <br />
              <span>
                <v-simple-table dense class="table-report">
                  <thead>
                    <tr>
                      <th class="title_left">Dia</th>
                      <th class="title_left">Turno</th>
                      <th class="title_left">Lugar</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="dia in dias" :key="dia.format('DDMMYYYYHHMMSS')">
                      {{
                        prepareTurno(dia, empleadoTurnos)
                      }}
                      <td>{{ dia.format("dddd DD/MM/YYYY") }}</td>
                      <td>{{ getTurno(dia, empleadoTurnos.empleado.id) }}</td>
                      <td>{{ getLugar(dia, empleadoTurnos.empleado.id) }}</td>
                    </tr>
                  </tbody>
                </v-simple-table>
              </span>

              <div
                v-if="
                  empleadoTurnos != empleadosTurnos[empleadosTurnos.length - 1]
                "
                class="html2pdf__page-break"
              ></div>
            </section>
          </section>
        </vue-html2pdf>
      </v-col>
    </v-row>
    <v-btn color="primary" @click="generateReport()"
      ><v-icon left dark> mdi-check </v-icon>Generar PDF</v-btn
    >
  </v-container>
</template>

<script>
import VueHtml2pdf from "vue-html2pdf";
import TurnosSemanalesFilter from "../../components/reports/TurnosSemanalesFilter.vue";
import moment from "moment";

export default {
  name: "ReporteTurnosSemanales",
  components: {
    VueHtml2pdf,
    TurnosSemanalesFilter,
  },
  data() {
    return {
      events: [],
      dias: [],
      /* */
      value: "",
      pageNumber: "1",
      empleadosTurnos: [],
      dateFormat: "YYYY-MM-DD",
      resultadosEmpleados: {},
      rango: "",
      empleado: "",
      sector: "",
      desde: null,
      hasta: null,
    };
  },
  created() {},
  methods: {
    generateReport() {
      this.$refs.html2Pdf.generatePdf();
    },
    getTurno(dia, empleadoId) {
      return this.resultadosEmpleados[empleadoId][dia].turno;
    },
    getLugar(dia, empleadoId) {
      return this.resultadosEmpleados[empleadoId][dia].lugar;
    },
    prepareTurno(dia, empleadoTurnos) {
      let desde;
      let hasta;
      let dateFormat = this.dateFormat;
      let resultado = null;
      let turnoLugar = { turno: null, lugar: null };

      empleadoTurnos.turnos.forEach(function (turno) {
        desde = null;
        hasta = null;
        desde = moment(turno.desde, dateFormat);

        if (turno.hasta) hasta = moment(turno.hasta, dateFormat);

        if (dia >= desde && !hasta) resultado = turno;

        if (dia >= desde && dia <= hasta) resultado = turno;
      });

      if (resultado)
        turnoLugar = {
          turno: resultado.turno.nombre,
          lugar: resultado.lugar ? resultado.lugar.nombre : "---",
        };
      else turnoLugar = { turno: "---", lugar: "---" };

      this.resultadosEmpleados[empleadoTurnos.empleado.id] = [];
      this.resultadosEmpleados[empleadoTurnos.empleado.id][dia] = turnoLugar;
    },
    processTurnos(turnos, desde, hasta, empleado, sector) {
      this.empleadosTurnos = turnos;

      let rDesde;
      let rHasta;
      let rDias;

      rDesde = moment(desde, "YYYY-MM-DD").locale("es");
      rHasta = moment(hasta, "YYYY-MM-DD").locale("es");
      // console.log("Hasta: " + rHasta);
      rDias = rHasta.diff(rDesde, "days");
      // console.log("diff dias: " + rDias);
      this.dias = [];
      for (let i = 0; i <= rDias; i++) {
        this.dias.push(rDesde.clone().add(i, "days"));
      }
      this.date;
      this.rango =
        rDesde.format("DD/MM/YYYY") + " - " + rHasta.format("DD/MM/YYYY");
      this.sector = this.$refs.reportFilter.sectores.find(
        (obj) => obj.id === sector
      ).nombre;
      this.empleado = empleado;

      // console.log("Dias array: " + this.dias);
      // rDesde.locale("es");
      // console.log("Formato Ok: " + rDesde.format("dddd DD/MM/YYYY"));
    },
  },
};
</script>
<style scoped>
.title_left {
  text-align: left;
}

.title {
  text-align: center;
  font-size: 30pt;
}

.subtitle {
  text-align: left;
  font-size: 12pt;
}

.pdf-content {
  margin: 50px;
}
.pdf-item {
  margin-top: 50px;
  margin-bottom: 50px;
}

.table-report {
  /* margin-top:100px; */
}
</style>
