<template>
  <div>
    <v-form v-model="formValidity" ref="form" @submit.prevent="onSubmit">
      <v-row>
        <v-col cols="6" md="4">
          <v-menu
            ref="menu_rango"
            v-model="menu_rango"
            :close-on-content-click="false"
            :return-value.sync="date_from"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="rango"
                label="Rango"
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
                :rules="rangoRules"
              ></v-text-field>
            </template>
            <v-date-picker
              locale="es-AR"
              v-model="rango"
              no-title
              scrollable
              range
            >
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="menu_rango = false">
                Cancelar
              </v-btn>
              <v-btn text color="primary" @click="$refs.menu_rango.save(rango)">
                OK
              </v-btn>
            </v-date-picker>
          </v-menu>
        </v-col>
        <v-col cols="6" md="4">
          <EmpleadoAutocomplete
            ref="empleadoAutoComplete"
            :conContratacion="true"
            @empleado-changed="onEmpleadoChanged"
          ></EmpleadoAutocomplete>
        </v-col>
        <!-- <v-col cols="12" md="2">
          <v-text-field v-model="search" label="Texto"></v-text-field>
        </v-col> -->
        <v-col cols="6" md="2">
          <v-select
            :items="sectores"
            item-text="nombre"
            item-value="id"
            label="Sectores"
            v-model="sector"
          ></v-select>
        </v-col>
        <v-col cols="6" md="2" class="col-btn-filter">
          <v-btn
            icon
            color="secondary"
            dark
            bottom
            @click="doFilter(true)"
            type="submit"
            ><v-icon>mdi-text-search</v-icon></v-btn
          >
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>

<script>
import EmpleadosService from "@/services/EmpleadosService.js";
import PresentismoService from "@/services/PresentismoService.js";
import EmpleadoAutocomplete from "../autocompletes/EmpleadoAutocomplete.vue";
import moment from "moment";

export default {
  name: "TurnosSemanalesFilter",
  components: {
    EmpleadoAutocomplete,
  },
  data() {
    return {
      empleado: null,
      empleadoDescripcion: null,
      turnos: [],
      rango: [],
      menu_rango: null,
      sectores: [],
      sector: 0,
      date_from: null,
      date_to: null,
      formValidity: false,
      dateFormat: "YYYY-MM-DD",
      rangoRules: [
        (v) => !!v || "Debe seleccionar un rango",
        (v) =>
          (v[0] !== "undefined" && v[1] !== "undefined") ||
          "Debe seleccionar un intervalo de fechas",
        (v) =>
          moment(v[0], this.dateFormat) <= moment(v[1], this.dateFormat) ||
          "La fecha de inicio debe ser menor a la de fin",
        (v) =>
          moment(v[1], this.dateFormat).diff(
            moment(v[0], this.dateFormat),
            "days"
          ) < 21 || "Seleccione un intervalo de hasta 20 dias",
      ],
    };
  },
  props: {
    pageNumber: {
      type: String,
      required: false,
    },
  },
  created() {
    this.date_from = moment().format("YYYY-MM-DD");
    this.date_to = moment().add(7, "days").format("YYYY-MM-DD");
    this.rango.push(this.date_from);
    this.rango.push(this.date_to);

    EmpleadosService.getSectores()
      .then((response) => {
        this.sectores = response.data.results;
        this.sectores.push({ id: 0, nombre: "Todos" });
        this.doFilter();
      })
      .catch((error) => console.log(error));
  },
  methods: {
    doFilter(resetPage = false) {
      let page = "1";
      if (resetPage) page = 1;
      else page = this.pageNumber;

      this.date_from = this.rango[0];
      this.date_to = this.rango[1];

      PresentismoService.reporteTurnosSemanales(
        this.date_from,
        this.date_to,
        this.empleado,
        this.sector
      )
        .then((response) => {
          this.turnos = response.data;
          this.$emit(
            "turnossemanales-changed",
            this.turnos,
            this.date_from,
            this.date_to,
            this.empleadoDescripcion,
            this.sector
          );
        })
        .catch((error) => console.log(error));
    },
    onEmpleadoChanged(event, descripcion) {
      this.empleado = event;
      this.empleadoDescripcion = descripcion;
      // this.$emit("empleado-changed", event);
    },
    onSubmit() {},
    validate() {
      this.$refs.form.validate();
    },
  },
  watch: {
    pageNumber: function (newVal, oldVal) {
      if (newVal && newVal.length) this.doFilter();
    },
  },
};
</script>
